<template>
      <div v-if="isMobile" class="container has-space">
        <MobileHeaderBack></MobileHeaderBack>
        <div class="mobile-account__container">
            <div class="signup-form__title">{{$t(title)}}</div>
            
            <!-- referral code -->
            <div class="mb-15rem">
                <div class="form-group">
                    <label class="form-label">{{$t('REFERRAL_CODE_SIGN_IN')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="userInfo.referralCode"  :placeholder="$t('REFERRAL_CODE')">
                    </div>
                </div>  
                <div class="form-group">
                    <label class="form-label">{{$t('REFERRAL_CODE')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="referralCode.inputReferralCode" v-on:keyup="inputReferralCodeEvent" :placeholder="$t('REFERRAL_CODE')">
                    </div>
                    <div class="form-tips ">
                        <div>{{$t('MY_REFERRAL_CODE_TIP')}}</div>
                    </div>
                </div>       
            </div>

            <!-- copy referral code -->
            <div class="display-flex-fe">
                <button class="btn enable w-100" @click="handleCopyReferralCode">{{$t('COPY_REFERRAL_CODE')}}</button>
            </div>
            
        </div>
    </div>
    <AccountIndex v-else>
        <div class="account-content__wrapper">
            <div class="account-content__title tc">{{$t(title)}}</div>
          
            <div class="change-password__form-group">
                <!-- referral code -->
                  <div class="form-group">
                    <label class="form-label">{{$t('REFERRAL_CODE_SIGN_IN')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="userInfo.referralCode"  :placeholder="$t('REFERRAL_CODE')">
                    </div>
                </div>  
                <div class="form-group">
                    <label class="form-label">{{$t('REFERRAL_CODE')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="referralCode.inputReferralCode" v-on:keyup="inputReferralCodeEvent" :placeholder="$t('REFERRAL_CODE')">
                    </div>
                    <div class="form-tips ">
                        <div>{{$t('MY_REFERRAL_CODE_TIP')}}</div>
                    </div>
            
                </div>
        
            </div>
            <!-- copy referral code -->
            <div class="display-flex-fe">
                <button class="btn enable" @click="handleCopyReferralCode">{{$t('COPY_REFERRAL_CODE')}}</button>
            </div>
          
        </div>
    </AccountIndex>
</template>

<script>
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import AccountIndex from '@/views/account/Index.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
   components: {
        AccountIndex,
        MobileHeaderBack
    },
   data() {
       return {
            title: '',
            referralCode: {
                inputReferralCode: '',
                isSaveBtnEnable: false,
            }

       }
   },
    watch:{

        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              this.init(); 
            }
        },
   },
   mounted() {
       this.init()
   },
    computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'isLogin',
            "userInfo",
            "currentLanguageObj",
            
        ]),
    },
    methods: {
        ...mapActions([
            "getRetrieveUserInfo",
            "setUserData",
            "getRetrieveUserInfoData",
            "toastrChangeEvent",
             "commonVerifyLogin"
        ]),
         init() {
            this.commonVerifyLogin();

            this.getRetrieveUserInfoData(); 
            this.title = this.$route.meta.name;
        
            //temporary fix after login api return referralCode = null 
            if (this.userInfo.referralCode === null) {
                this.getRetrieveUserInfoData();
            } else {           
                this.referralCode.inputReferralCode = `${window.location.protocol}//${window.location.host}/${this.currentLocale}/signup?code=${this.userInfo.referralCode}`;
            }
        },
        
        // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone:this.$tools.getCurrentTimeZone()
            }
            const result = await this.getRetrieveUserInfo(params);
      
            this.setUserData(result.result.data);
            this.referralCode.inputReferralCode = `${window.location.protocol}//${window.location.host}/${this.currentLocale}/signup?code=${this.userInfo.referralCode}`;
        },

        handleCopyReferralCode() {
            /* Get the text field */
            var copyText = this.$refs.referralCodeId;

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value);

            /* Alert the copied text */
              let params= {
                isOpen:true,
                msg:'REFERRAL_CODE_COPIED',     
            }
            this.toastrChangeEvent(params)
            // this.$root.toastrMsg = 'REFERRAL_CODE_COPIED';
            // this.$root.$refs.toastrRef.handleOpen();
          
        }
    },
}
</script>

<style>

</style>